import React from "react";
import SearchResultsTemplate from "../../../../templates/search-results-template"

const SearchResultsResiLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultsTemplate 
                locationname="/residential/property/to-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="long-lets" 
                pstatustype="To Rent" 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    )
}

export default SearchResultsResiLettings;